import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./paginas/home/home.module').then((m) => m.HomePageModule),
    pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'carrinho',
    loadChildren: () =>
      import('./paginas/carrinho/carrinho.module').then(
        (m) => m.CarrinhoPageModule
      ),
  },
  {
    path: 'pagamento',
    loadChildren: () =>
      import('./paginas/pagamento/pagamento.module').then(
        (m) => m.PagamentoPageModule
      ),
  },
  {
    path: 'sucesso',
    loadChildren: () =>
      import('./paginas/sucesso/sucesso.module').then(
        (m) => m.SucessoPageModule
      ),
  },
  {
    path: 'minhas-compras',
    loadChildren: () =>
      import('./paginas/minhas-compras/minhas-compras.module').then(
        (m) => m.MinhasComprasPageModule
      ),
  },
  {
    path: 'meus-atendimentos',
    loadChildren: () =>
      import('./paginas/meus-atendimentos/meus-atendimentos.module').then(
        (m) => m.MeusAtendimentosPageModule
      ),
  },
  {
    path: 'meus-orcamentos',
    loadChildren: () =>
      import('./paginas/meus-orcamentos/meus-orcamentos.module').then(
        (m) => m.MeusOrcamentosPageModule
      ),
  },
  {
    path: 'meus-exames',
    loadChildren: () =>
      import('./paginas/meus-exames/meus-exames.module').then(
        (m) => m.MeusExamesPageModule
      ),
  },
  {
    path: 'minhas-compras/:pedido_id/detalhes',
    loadChildren: () =>
      import(
        './paginas/minhas-compras-detalhes/minhas-compras-detalhes.module'
      ).then((m) => m.MinhasComprasDetalhesPageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./paginas/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'perfil',
    loadChildren: () =>
      import('./paginas/perfil/perfil.module').then((m) => m.PerfilPageModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./paginas/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'agendamentos',
    loadChildren: () =>
      import('./paginas/agendamento/agendamento.module').then(
        (m) => m.AgendamentoPageModule
      ),
  },
  {
    path: 'especialidade/:especialidade_id/profissional/:profissional_id',
    loadChildren: () =>
      import(
        './paginas/profissional-selecionado/profissional-selecionado.module'
      ).then((m) => m.ProfissionalSelecionadoPageModule),
  },
  {
    path: 'especialidade/:especialidade_id/profissional/:profissional_id/procedimento-grupo/:grupo_id',
    loadChildren: () =>
      import(
        './paginas/procedimento-selecionado/procedimento-selecionado.module'
      ).then((m) => m.ProcedimentoSelecionadoPageModule),
  },
  {
    path: 'especialidade/:especialidade_id/profissional/:profissional_id/procedimento/:procedimento_id/horario-selecionado/:profissionais_horarios_disponibilidade_id',
    loadChildren: () =>
      import('./paginas/horario-selecionado/horario-selecionado.module').then(
        (m) => m.HorarioSelecionadoPageModule
      ),
  },
  {
    path: 'procedimentos-externos',
    loadChildren: () =>
      import(
        './paginas/procedimentos-externos/procedimentos-externos.module'
      ).then((m) => m.ProcedimentosExternosPageModule),
  },
  {
    path: 'procedimentos-externos/selecionados',
    loadChildren: () =>
      import(
        './paginas/procedimentos-externos-selecionados/procedimentos-externos-selecionados.module'
      ).then((m) => m.ProcedimentosExternosSelecionadosPageModule),
  },
  {
    path: 'conta',
    loadChildren: () =>
      import('./paginas/conta/conta.module').then((m) => m.ContaPageModule),
  },
  {
    path: 'meus-agendamentos',
    loadChildren: () =>
      import('./paginas/meus-agendamentos/meus-agendamentos.module').then(
        (m) => m.MeusAgendamentosPageModule
      ),
  },
  {
    path: 'contato',
    loadChildren: () =>
      import('./paginas/contato/contato.module').then(
        (m) => m.ContatoPageModule
      ),
  },
  {
    path: 'onde-estamos',
    loadChildren: () =>
      import('./paginas/onde-estamos/onde-estamos.module').then(
        (m) => m.OndeEstamosPageModule
      ),
  },
  {
    path: 'termos',
    loadChildren: () =>
      import('./paginas/termos/termos.module').then((m) => m.TermosPageModule),
  },
  {
    path: 'procedimentos-orcamentos',
    loadChildren: () =>
      import(
        './paginas/procedimentos-orcamentos/procedimentos-orcamentos.module'
      ).then((m) => m.ProcedimentosOrcamentosPageModule),
  },
  {
    path: 'exame-citologico',
    loadChildren: () =>
      import('./paginas/exame-citologico/exame-citologico.module').then(
        (m) => m.ExameCitologicoPageModule
      ),
  },
  {
    path: 'profissional/login',
    loadChildren: () =>
      import('./paginas/profissional/login/login.module').then(
        (m) => m.LoginPageModule
      ),
  },
  {
    path: 'profissional/home',
    loadChildren: () =>
      import('./paginas/profissional/home/home.module').then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: 'profissional/meus-agendamentos',
    loadChildren: () =>
      import('./paginas/profissional/agendamento/agendamento.module').then(
        (m) => m.AgendamentoPageModule
      ),
  },
  {
    path: 'profissional/disponibilidades',
    loadChildren: () =>
      import(
        './paginas/profissional/disponibilidades/disponibilidades.module'
      ).then((m) => m.DisponibilidadesPageModule),
  },
  {
    path: 'profissional/conta',
    loadChildren: () =>
      import('./paginas/profissional/conta/conta.module').then(
        (m) => m.ContaPageModule
      ),
  },
  {
    path: 'profissional/perfil',
    loadChildren: () =>
      import('./paginas/profissional/perfil/perfil.module').then(
        (m) => m.PerfilPageModule
      ),
  },
  {
    path: 'profissional/locais-atendimentos',
    loadChildren: () =>
      import(
        './paginas/profissional/locais-atendimentos/locais-atendimentos.module'
      ).then((m) => m.LocaisAtendimentosPageModule),
  },
  {
    path: 'profissional/dias-atendimentos',
    loadChildren: () =>
      import(
        './paginas/profissional/dias-atendimentos/dias-atendimentos.module'
      ).then((m) => m.DiasAtendimentosPageModule),
  },
  {
    path: 'profissional/pacientes/:paciente_id',
    loadChildren: () =>
      import('./paginas/profissional/pacientes/pacientes.module').then(
        (m) => m.PacientesPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
