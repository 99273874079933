import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formataSexo'
})
export class FormataSexoPipe implements PipeTransform {
  transform(sexo: Number): String {
    return sexo == 1 ? "Feminino" : "Masculino";
  }
}
