import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formataTelefone'
})
export class FormataTelefonePipe implements PipeTransform {

  transform(telefone: String): String {
    telefone = telefone.replace(/[^\d]/g, "");
    telefone = telefone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    if( telefone.match(/-/) == null ){
      telefone = telefone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }

    return telefone
  }

}
