import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formataRG'
})
export class FormataRgPipe implements PipeTransform {

  transform(rg: String): String {
    rg = rg.replace(/[^\d]/g, "");
    return rg.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, "$1.$2.$3-$4");
  }

}
