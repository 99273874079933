import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formataCPF'
})
export class FormataCpfPipe implements PipeTransform {

  transform(cpf: String): String {
    cpf = cpf.replace(/[^\d]/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

}