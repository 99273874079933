import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AlertModule } from '@coreui/angular';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { FormataCpfPipe } from './pipes/formata-cpf.pipe';
import { FormataRgPipe } from './pipes/formata-rg.pipe';
import { FormataSexoPipe } from './pipes/formata-sexo.pipe';
import { FormataTelefonePipe } from './pipes/formata-telefone.pipe';


registerLocaleData(ptBr);

@NgModule({
  declarations: [
    AppComponent,
    FormataSexoPipe, FormataCpfPipe, FormataRgPipe, FormataTelefonePipe
  ],
  exports: [
    FormataSexoPipe, FormataCpfPipe, FormataRgPipe, FormataTelefonePipe
  ],
  imports: [
    BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, FormsModule,
    AlertModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
